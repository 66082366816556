import * as React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

const Footer = (): JSX.Element => {
  const messages = useIntl().messages;
  const intl = useIntl();

  return (
    <footer id="aps-footer" className={"ui horizontally padded grid " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
      <div className="row">
        <div className="six wide column">
          <h4 className="ui inverted header">{messages.useful_links}</h4>

          <div className="ui relaxed unordered list">
            <div className="item">
              <i className={"icon inverted chevron " + (intl.locale === "en" ? "right" : "left")}/>
              <a href="http://dab.gov.af/en" className="content">{messages.da_afghanistan_bank}</a>
            </div>
            <div className="item">
              <i className={"icon inverted chevron " + (intl.locale === "en" ? "right" : "left")}/>
              <a className="content" href="https://dab.gov.af/laws">{messages.laws}</a>
            </div>
          </div>
        </div>

        <div className="ten wide column">
          <h4 className="ui inverted header">{messages.contact_info}</h4>

          <div className="ui relaxed unordered list">
            <div className="item">{messages.address}: {messages.aps_address}</div>
            <div className="item">{messages.phone}: <span dir="ltr">+93(0) 20 22 106 22</span></div>
            <div className="item">{messages.email}: info@aps.gov.af</div>
            <div className="item">
              <button className="ui icon basic grey button">
                <i className="icon facebook"/>
              </button>
              <button className="ui icon basic grey button">
                <i className="icon twitter"/>
              </button>
              <button className="ui icon basic grey button">
                <i className="icon linkedin"/>
              </button>
              <button className="ui icon basic grey button">
                <i className="icon youtube"/>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="bottom-row" className="row">
        <div className="six wide column">
          <div className={"ui horizontal unordered list " + (intl.locale === "en" ? "left floated" : "right floated")}>
            <a className="item" href="/">{messages.home}</a>
            <a className="item" href="/news/">{messages.news}</a>
            <a className="item" href="/events/">{messages.aps_events}</a>
            <a className="item" href="/careers/">{messages.career}</a>
          </div>
        </div>

        <div className="ten wide column text-ltr">
          <div className="ui horizontal unordered list">
            <span className="ui red text item">&copy; {messages.rights_reserved}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;