import {graphql, useStaticQuery} from "gatsby";
import '../styles/global-styles.css';
import * as React from 'react';
import {Dropdown, Menu, Search} from 'semantic-ui-react';
import {GatsbyImage} from "gatsby-plugin-image";
import {changeLocale, IntlContextConsumer, useIntl} from 'gatsby-plugin-react-intl';
import {Container, Form, Nav, Navbar, NavDropdown} from "react-bootstrap";

const Header = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const data = useStaticQuery(graphql`{
    aps_logo: file(relativePath: {eq: "aps-logo.png"}) {
      childImageSharp {
        gatsbyImageData(
          height: 80
          width: 240,
          quality: 100,
          layout: FIXED,
          transformOptions: {cropFocus: ATTENTION, fit: INSIDE})
      }
    }
    dab_logo: file(relativePath: {eq: "dab-logo.png"}) {
      childImageSharp {
        gatsbyImageData(
          height: 100
          width: 240,
          quality: 100,
          layout: FIXED,
          transformOptions: {cropFocus: ATTENTION, fit: INSIDE})
      }
    }
  }
  `);

  return <>
    <header className={"hidden_header " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
      <div style={{
        height: '35px',
        lineHeight: '35px',
        fontSize: '0.9rem',
        color: '#777777'
      }}>
        <div className="ui grid container transition header-padding" style={{marginTop: '0px'}}>
          <span>{messages.your_payments_partner}</span>
          <a href="tel:+93202210622" aria-label={"contact"} dir="ltr">
            <i className="icon phone"/>
            +93(0) 20 22 106 22
          </a>
          <a href="mailto:info@aps.gov.af" className={"contact-direction"} aria-label={"email"} dir="ltr">
            <i className="icon mail"/>
            info@aps.gov.af
          </a>
          <span>{messages.aps_address}</span>

          <span>
          <a target="_blank" href="https://www.facebook.com/AfghanistanPaymentsSystems/" aria-label={"facebook"} rel="noreferrer">
            <i className="icon facebook"/>
          </a>
          <a target="_blank" href="https://twitter.com/Afghanistan_Pay" aria-label={"twitter"} rel="noreferrer">
            <i className="icon twitter"/>
          </a>
          <a target="_blank" href="https://af.linkedin.com/in/afghanistan-payments-systems-71130295" aria-label={"linkedin"} rel="noreferrer">
            <i className="icon linkedin"/>
          </a>
          <a target="_blank" href="https://bit.ly/2ETalYY" aria-label={"youtube"} rel="noreferrer">
            <i className="icon youtube"/>
          </a>
        </span>
          <span className={(intl.locale === "en" ? "en-span" : "fa-pa-span")}>
          <IntlContextConsumer>
            {({languages, language: currentLocale}) =>
              languages.map(language => (
                <a key={language} onClick={() => changeLocale(language)}
                   style={{
                     color: currentLocale === language ? `green` : `black`,
                     margin: 3,
                     cursor: `pointer`
                   }}
                >
                  <span className="text-rtl">
                  {language === `pa` && <> پشتو </>}
                  </span>
                  <span className="text-rtl">
                  {language === `fa` && <> دری</>}
                  </span>
                  <span>
                  {language === `en` && <>en</>}
                  </span>
                </a>
              ))
            }
          </IntlContextConsumer>
        </span>
        </div>
      </div>
      <Menu attached='bottom' secondary
            style={{padding: '0px 160px', backgroundColor: '#fff', left: 'auto', right: 0}}
            className={"center-menu " + (intl.locale === "en" ? "left" : "right")}>
        <Dropdown.Item style={{padding: '0px'}}>
          <GatsbyImage image={data.aps_logo.childImageSharp.gatsbyImageData} alt="logo"/>
        </Dropdown.Item>
        <Dropdown.Item style={{padding: '0px'}}>
          <GatsbyImage image={data.dab_logo.childImageSharp.gatsbyImageData} alt="logo"/>
        </Dropdown.Item>
        {/* <Dropdown.Item image={''}/>*/}
        <Dropdown.Item href="/" className={"hovering_menu"}>{messages.home}</Dropdown.Item>
        <Dropdown item simple text={`${messages.whoWeAre}`} icon={null} className={"hovering_menu"}>
          <Dropdown.Menu className={(intl.locale === "en" ? "item-text-align-left" : "item-text-align-right")}>
            <Dropdown.Item href="/about/" className={"hovering_menu"}><span>{messages.about_us}</span></Dropdown.Item>
            <Dropdown.Item href="/vision-and-mission/" className={"hovering_menu"}>{messages.vision_and_mission}</Dropdown.Item>
            <Dropdown.Item href="/key-objective-roles/" className={"hovering_menu"}>{messages.key_objectives_roles}</Dropdown.Item>
            <Dropdown.Item href="/corporate-governance/" className={"hovering_menu"}>{messages.corporate_governance}</Dropdown.Item>
            <Dropdown.Item href="/organizational-chart/" className={"hovering_menu"}>{messages.organizational_chart}</Dropdown.Item>
            <Dropdown.Item href="/executive-board-of-dab/" className={"hovering_menu"}>{messages.executive_board_dab}</Dropdown.Item>
            <Dropdown.Item href="/board-of-expertise/" className={"hovering_menu"}>{messages.board_of_expertise}</Dropdown.Item>
            <Dropdown.Item href="/board-of-management/" className={"hovering_menu"}>{messages.board_of_management}</Dropdown.Item>
            <Dropdown.Item href="/policies-and-manuals/" className={"hovering_menu"}>{messages.policies_and_manuals}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text={`${messages.whatWeDo}`} icon={null} className={"hovering_menu"}>
          <Dropdown.Menu className={(intl.locale === "en" ? "item-text-align-left" : "item-text-align-right")}>
            <Dropdown item basic text={`${messages.banks_and_mfi_institutions}`} icon={null} className={"hovering_menu"}>
              <Dropdown.Menu className={(intl.locale === "en" ? "item-text-align-left" : "item-text-align-right")}>
                <Dropdown.Item href="/af-pay/" className={"hovering_menu"}>{messages.domestic_card_scheme}</Dropdown.Item>
                <Dropdown.Item href="/card-personalization/" className={"hovering_menu"}>{messages.card_personalization}</Dropdown.Item>
                <Dropdown.Item href="/merchant-driving-monitoring/"
                               className={"hovering_menu"}>{messages.merchant_driving_and_monitoring}</Dropdown.Item>
                <Dropdown.Item href="/access-to-international-payment-schemes/"
                               className={"hovering_menu"}>{messages.access_to_international_payment_schemes}</Dropdown.Item>
                <Dropdown.Item href="/transaction-authorization-clearing-settlement/"
                               className={"hovering_menu"}>{messages.transaction_authorization_clearing_and_settlement}</Dropdown.Item>
                <Dropdown.Item href="/fraud-dispute-management-system/"
                               className={"hovering_menu"}>{messages.fraud_and_dispute_management_system}</Dropdown.Item>
                <Dropdown.Item href="/sms-mobile-banking/" className={"hovering_menu"}>{messages.sms_and_mobile_banking}</Dropdown.Item>
                <Dropdown.Item href="/ivr-banking/" className={"hovering_menu"}>{messages.ivr_banking}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown.Item href="/public-services/" className={"hovering_menu"}>{messages.public_services}</Dropdown.Item>
            <Dropdown item simple text={`${messages.electronic_money_institutions}`} icon={null} className={"hovering_menu"}>
              <Dropdown.Menu className={(intl.locale === "en" ? "item-text-align-left" : "item-text-align-right")}>
                <Dropdown.Item href="/interoperability/" className={"hovering_menu"}>{messages.interoperability}</Dropdown.Item>
                <Dropdown.Item href="/prepaid-card/" className={"hovering_menu"}>{messages.prepaid_cards}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown item simple text={`${messages.payment_institutions}`} icon={null} className={"hovering_menu"}>
              <Dropdown.Menu className={(intl.locale === "en" ? "item-text-align-left" : "item-text-align-right")}>
                <Dropdown.Item href="/payment-service-providers/payment-institutions/"
                               className={"hovering_menu"}>{messages.payment_institutions}</Dropdown.Item>
                <Dropdown.Item href="/atm-driving-and-monitoring/" className={"hovering_menu"}>{messages.atm_driving_and_monitoring}</Dropdown.Item>
                <Dropdown.Item href="/prepaid-cards/" className={"hovering_menu"}>{messages.prepaid_cards}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text={`${messages.whyAPS}`} icon={null} className={"hovering_menu"}>
          <Dropdown.Menu className={(intl.locale === "en" ? "item-text-align-left" : "item-text-align-right")}>
            <Dropdown.Item href="/stakeholder-benefits/" className={"hovering_menu"}>{messages.stakeholder_benefits}</Dropdown.Item>
            <Dropdown.Item href="/aps-guiding-principle/" className={"hovering_menu"}>{messages.aps_guiding_principle}</Dropdown.Item>
            <Dropdown.Item href="/competitive-advantage/" className={"hovering_menu"}>{messages.competitive_advantages}</Dropdown.Item>
            <Dropdown.Item href="/critical-success-factors/" className={"hovering_menu"}>{messages.critical_success_factors}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown item simple text={`${messages.newsAndEvents}`} icon={null} className={"hovering_menu"}>
          <Dropdown.Menu className={(intl.locale === "en" ? "item-text-align-left" : "item-text-align-right")}>
            <Dropdown.Item href="/news/" className={"hovering_menu"}>{messages.news}</Dropdown.Item>
            <Dropdown.Item href="/events/" className={"hovering_menu"}>{messages.aps_events}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown.Item href="/careers/" className={"hovering_menu"}>{messages.careers}</Dropdown.Item>
        <Dropdown.Item href="/contact-us/" className={"hovering_menu"}>{messages.contacts}</Dropdown.Item>
        <Dropdown.Item className={"hovering_menu"}><Search loading={false}/> </Dropdown.Item>
      </Menu>
    </header>
    <Container className={"container_hidden " + (intl.locale === "en" ? "text-ltr" : "text-rtl")}>
      <Navbar expand={"lg"}>
        <Navbar.Brand/>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <i className="icon angle down"/>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Item className={"naving_item"}>
              <div className="ui grid container transition">
                <Navbar.Text>{messages.your_payments_partner}</Navbar.Text>
                <a href="tel:+93202210622">
                  <i className="icon phone"/>
                  +93(0) 20 2210622
                </a>
                <a href="mailto:info@aps.gov.af">
                  <i className="icon mail"/>
                  info@aps.gov.af
                </a>
                <Navbar.Text>{messages.aps_address}</Navbar.Text>
                <span>
          <a target="_blank" href="https://www.facebook.com/AfghanistanPaymentsSystems/" rel="noreferrer">
            <i className="icon facebook"/>
          </a>
          <a target="_blank" href="https://twitter.com/Afghanistan_Pay" rel="noreferrer">
            <i className="icon twitter"/>
          </a>
          <a target="_blank" href="https://af.linkedin.com/in/afghanistan-payments-systems-71130295" rel="noreferrer">
            <i className="icon linkedin"/>
          </a>
         </span>
                <span>
          <IntlContextConsumer>
            {({languages, language: currentLocale}) =>
              languages.map(language => (
                <a key={language} onClick={() => changeLocale(language)}
                   style={{
                     color: currentLocale === language ? `green` : `black`,
                     margin: 2,
                     textDecoration: `underline`,
                     cursor: `pointer`,
                   }}
                >
                  {language === `pa` && <>پشتو</>}
                  {language === `fa` && <> دری </>}
                  {language === `en` && <>en</>}
                </a>
              ))
            }
          </IntlContextConsumer>
        </span>
              </div>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
    <NavDropdown.Divider/>
    <Container className={(intl.locale === "en" ? "text-ltr" : "text-rtl")}>
      <Navbar expand={"lg"}>
        <Navbar.Brand href="/">
          <GatsbyImage image={data.aps_logo.childImageSharp.gatsbyImageData} alt="logo"/>
        </Navbar.Brand>
        <Navbar.Toggle/>
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Nav.Link href="/">{messages.home}</Nav.Link>
            <NavDropdown title={messages.whoWeAre} id="basic-nav-dropdown">
              <NavDropdown.Item href="/about/">{messages.about_us}</NavDropdown.Item>
              <NavDropdown.Item href="/vision-and-mission/">{messages.vision_and_mission}</NavDropdown.Item>
              <NavDropdown.Item href="/message-of-md/">{messages.message_of_md}</NavDropdown.Item>
              <NavDropdown.Item href="/key-objective-roles/">{messages.key_objectives_roles}</NavDropdown.Item>
              <NavDropdown.Item href="/corporate-governance/">{messages.corporate_governance}</NavDropdown.Item>
              <NavDropdown.Item href="/organizational-chart/">{messages.organizational_chart}</NavDropdown.Item>
              <NavDropdown.Item href="/executive-board-of-dab/">{messages.executive_board_dab}</NavDropdown.Item>
              <NavDropdown.Item href="/board-of-expertise/">{messages.board_of_expertise}</NavDropdown.Item>
              <NavDropdown.Item href="/board-of-management/">{messages.board_of_management}</NavDropdown.Item>
              <NavDropdown.Item href="/policies-and-manuals/">{messages.policies_and_manuals}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={messages.whatWeDo} id="basic-nav-dropdown">
              <NavDropdown.Item href="/merchant-driving-monitoring/">{messages.merchant_driving_and_monitoring}</NavDropdown.Item>
              <NavDropdown.Item href="/access-to-international-payment-schemes/">{messages.access_to_international_payment_schemes}</NavDropdown.Item>
              <NavDropdown.Item
                href="/transaction-authorization-clearing-settlement/">{messages.fraud_and_dispute_management_system}</NavDropdown.Item>
              <NavDropdown.Item href="/fraud-dispute-management-system/">{messages.sms_and_mobile_banking}</NavDropdown.Item>
              <NavDropdown.Item href="/sms-mobile-banking/">{messages.organizational_chart}</NavDropdown.Item>
              <NavDropdown.Item href="/ivr-banking/">{messages.ivr_banking}</NavDropdown.Item>
              <NavDropdown.Item href="/card-personalization/">{messages.card_personalization}</NavDropdown.Item>
              <NavDropdown.Item href="/af-pay/">{messages.domestic_card_scheme}</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={messages.whyAPS} id="basic-nav-dropdown">
              <NavDropdown.Item href="/stakeholder-benefits/">{messages.stakeholder_benefits}</NavDropdown.Item>
              <NavDropdown.Item href="/aps-guiding-principle/">{messages.aps_guiding_principle}</NavDropdown.Item>
              <NavDropdown.Item href="/competitive-advantage/">{messages.competitive_advantages}</NavDropdown.Item>
              <NavDropdown.Item href="/critical-success-factors/">{messages.critical_success_factors}</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={messages.newsAndEvents} id="basic-nav-dropdown">
              <NavDropdown.Item href="/news/">{messages.news}</NavDropdown.Item>
              <NavDropdown.Item href="/events/">{messages.aps_events}</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/careers/">{messages.careers}</Nav.Link>
            <Nav.Link href="/contact-us/">{messages.contacts}</Nav.Link>
          </Nav>
          <Form inline>
            <Search loading={false}/>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </>;
};

export default Header;